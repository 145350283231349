import React, { useState } from 'react';
import DownloadPDFButton from 'components/DownloadPDFButton';
import DownloadIcon from 'public/assets/user/download.svg';
import NWLSpinner from 'components/NWLSpinner';

function UserDownloadPdfButton(props) {
  const [visited, setVisited] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);

  const clickHandler = () => {
    setVisited(true);
  };
  return (
    <DownloadPDFButton
      onDownloadPDFClick={clickHandler}
      onLoadingPdf={setLoadingPdf}
      {...props}
    >
      {loadingPdf ? (
        <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
          <NWLSpinner smallView />
        </div>
      ) : (
        <div className="flex user-download-button">
          Download
          <img
            src={DownloadIcon.src}
            className="general_grid_icon"
            style={{ marginLeft: '.5rem' }}
          />
        </div>
      )}
    </DownloadPDFButton>
  );
}
export default UserDownloadPdfButton;
