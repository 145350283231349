import React from 'react';
import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Globe from 'public/assets/user/globe.svg';
import UserDownloadPdfButton from 'components/user/UserDownloadPDFButton';

export default function SurveyRow({ survey, goal, order, inWindow }) {
  const router = useRouter();
  const formatDate = new Date(survey.created);

  const goToReport = () => {
    router.push(`/survey/report/${survey.id}`);
  };

  const setGoals = () => {
    router.push(`/survey/${survey.cohort_id}/goals/reflections`);
  };

  const handleEditSurvey = () => {
    router.push(`/survey?code=${survey.cohort_id}`);
  };

  if (!survey.submitted) {
    return (
      <div className="user-profile-surveye-row" style={{ order }}>
        <div className="flex items-center user-survey-row">
          <div className="user-survey-date">
            {`${
              formatDate.getUTCMonth() + 1
            }/${formatDate.getUTCDate()}/${formatDate.getUTCFullYear()}`}
          </div>
          <p className="italic mb-0" style={{ marginLeft: '1.2rem' }}>
            Complete the survey to see the results
          </p>
        </div>

        <Button
          color="primary"
          style={{ borderRadius: '4px' }}
          onClick={handleEditSurvey}
        >
          Resume
        </Button>
      </div>
    );
  }
  return (
    <div className="user-profile-surveye-row" style={{ order }}>
      <div className="flex items-center user-survey-row">
        <div className="user-survey-date">
          {`${
            formatDate.getUTCMonth() + 1
          }/${formatDate.getUTCDate()}/${formatDate.getUTCFullYear()}`}
        </div>
        {survey.immediateFlow ? (
          <>
            <Button
              color="none"
              className="flex items-center p-0 user-view-report"
              onClick={goToReport}
            >
              View report
              <img
                src={Globe.src}
                style={{ width: '1.5rem', marginLeft: '.5rem' }}
                alt="globe"
              />
            </Button>
            <div style={{ marginLeft: '1.2rem' }}>
              <UserDownloadPdfButton activeSurveyID={survey.id} name="user" />
            </div>
          </>
        ) : (
          <p className="italic mb-0" style={{ marginLeft: '1.2rem' }}>
            Ask your facilitator about receiving your report
          </p>
        )}
      </div>
      {survey.completed && (
        <div className="profile-survey-buttons">
          {inWindow && (
            <Button
              color="primary"
              style={{ borderRadius: '4px' }}
              onClick={handleEditSurvey}
            >
              Edit Survey
            </Button>
          )}
          {(!goal || !goal.completed) && (
            <Button color="primary" style={{ borderRadius: '4px' }} onClick={setGoals}>
              Define goals
            </Button>
          )}
        </div>
      )}
      {!survey.completed && (
        <Button
          color="primary"
          style={{ borderRadius: '4px' }}
          onClick={handleEditSurvey}
        >
          Resume
        </Button>
      )}
    </div>
  );
}

SurveyRow.propTypes = {
  survey: PropTypes.shape({
    created: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    cohort_id: PropTypes.string.isRequired,
    immediateFlow: PropTypes.bool,
    completed: PropTypes.bool,
  }).isRequired,
  goal: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  inWindow: PropTypes.bool,
};

SurveyRow.defaultProps = {
  inWindow: false,
};
