import VideosIcon from 'public/assets/Videos_Icon.svg';
import DownloadIcon from 'public/assets/Download_Icon_Button.svg';
import NeedHelpIcon from 'public/assets/Need_Help_Icon.svg';
import NetworkingMythsPDF from 'public/assets/Networking_Myths_Job_Aid-Network_Leader.pdf';
import TipsAndTricksPDF from 'public/assets/Networking_Tips_and_Tricks-Network_Leader.pdf';
import { Button } from 'reactstrap';
import { useRouter } from 'next/navigation';

export const UserLearningMaterials = ({ isTrainer = false }) => {
  const router = useRouter();

  const goToTrainersView = () => {
    router.push(`/trainer`);
  };

  return (
    <div className="user-materials">
      <div className="user-section-title w-full">Learning Materials</div>
      <div style={{ fontSize: '1rem' }}>
        <div style={{ marginTop: '1.5rem' }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vimeo.com/showcase/6995100"
            className="flex items-center"
          >
            <img src={VideosIcon.src} style={{ width: '1.5rem', marginRight: '.5rem' }} />
            Videos
          </a>
        </div>
        <div style={{ marginTop: '1.5rem' }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={TipsAndTricksPDF}
            className="flex items-center"
          >
            <img
              src={DownloadIcon.src}
              style={{ width: '1.5rem', marginRight: '.5rem' }}
            />
            Tips and Tricks
          </a>
        </div>
        <div style={{ marginTop: '1.5rem' }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={NetworkingMythsPDF}
            className="flex items-center"
          >
            <img
              src={DownloadIcon.src}
              style={{ width: '1.5rem', marginRight: '.5rem' }}
            />
            Network Myths
          </a>
        </div>
        <div style={{ marginTop: '1.5rem' }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:info@NetworkLeader.com"
            className="flex items-center"
          >
            <img
              src={NeedHelpIcon.src}
              style={{ width: '1.5rem', marginRight: '.5rem' }}
            />
            Need Help?
          </a>
          {isTrainer && (
            <Button color="primary" onClick={goToTrainersView} className="mt-4">
              Go to trainers view
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
