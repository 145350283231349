import React from 'react';
import PorpTypes from 'prop-types';

function UserProfileTopBanner({ email, name }) {
  return (
    <div className="bg-blue w-full flex mt-4 user-top-banner">
      <div className="user-display-name">{`${name}`}</div>
      <div className="flex items-center">
        <div>{`${email}`}</div>
        <a
          style={{ marginLeft: '.5rem', color: 'white' }}
          className="p-0 border-0 inline bg-transparent font-base underline"
          href="/api/auth/logout"
        >
          Logout
        </a>
      </div>
    </div>
  );
}

UserProfileTopBanner.propTypes = {
  email: PorpTypes.string.isRequired,
  name: PorpTypes.string.isRequired,
};

export default UserProfileTopBanner;
