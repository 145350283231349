const findGoal = ({ userGoals, userSurvey }) => {
  let goal = userGoals.find((e) => e.surveyId === userSurvey.id);
  if (!goal) {
    goal = userGoals.filter(
      ({ created, cohort_id }) =>
        cohort_id === userSurvey.cohort_id &&
        new Date(Number(created)) > new Date(userSurvey.created)
    );
    goal = goal.slice(-1)[0];
  }
  return goal;
};

export default findGoal;
