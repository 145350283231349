import fetch from 'isomorphic-unfetch';
import React from 'react';
import PropTypes from 'prop-types';

const downloadPDF = async (
  e,
  id,
  name = '',
  activeSurveyID,
  onDownloadPDFClick,
  onLoadingPdf
) => {
  e.preventDefault();
  if (!activeSurveyID) return; // FIXME avoid /api/report/null calls
  onLoadingPdf(true);

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const reportResponse = await fetch(`/api/report/${activeSurveyID}`, { headers });
  if (!reportResponse.ok) {
    throw new Error('error requesting report id');
  }

  const PDF = await reportResponse.blob();
  const blobURL = window.URL.createObjectURL(PDF);
  const link = document.createElement('a');
  link.href = blobURL;
  link.download = `LND_Report_${name.trim().replace(/ /g, '_')}.pdf`;
  onLoadingPdf(false);
  onDownloadPDFClick();
  link.click();
  link.remove();
};

function DownloadPDFButton({
  id,
  name,
  activeSurveyID,
  children,
  onDownloadPDFClick = () => {},
  onLoadingPdf,
  customStyles = {},
}) {
  const executeDownload = (e) =>
    downloadPDF(e, id, name, activeSurveyID, onDownloadPDFClick, onLoadingPdf);
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyPress={executeDownload}
      style={customStyles}
      onClick={executeDownload}
      className="with-tool-tip"
    >
      <span className="tool-tip-text">Download PDF</span>
      {children}
    </div>
  );
}

DownloadPDFButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  activeSurveyID: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onDownloadPDFClick: PropTypes.func,
  onLoadingPdf: PropTypes.func.isRequired,
  customStyles: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
};

DownloadPDFButton.defaultProps = {
  onDownloadPDFClick: () => {},
  customStyles: {},
};

export default DownloadPDFButton;
